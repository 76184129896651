<template>
	<div class="Citys">城市切换</div>
</template>
<script>
export default {
	name: "Citys",
	data() {
		return {};
	},
	created() {},
	methods: {},
};
</script>
<style scoped lang="scss">
.Citys {
	@extend .w100;
	@extend .h100;
}
</style>
